export const ACCOUNT_REGISTRATION = {
  path: "account-registration",
  name: "Account Registration",
  ACCOUNT_REGISTRATION_ID: {
    path: ":tenantId/detail",
    name: "Account Registration Detail",
  },
};

export const ACTIVITY_LOG = {
  path: "activity-log",
  name: "Activity Log",
  ACTIVITY_LOG_ID: {
    path: ":logId/detail",
    name: "Activity Log Detail",
  },
};
